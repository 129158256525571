.a-uploadimage {
	&_wapper {
		position: relative;
		height: 0;
		margin: 0 auto;

		&:before {
			display: inline-block;
			content: "";
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transition: opacity 0.3s ease-in-out;
		}

		&:hover:before {
			opacity: 0.5;
		}
	}

	&_inputupload {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		height: 100%;
		font-size: 0;
		vertical-align: middle;
		cursor: pointer;
		opacity: 0;
		transform: translate(-50%, -50%);
	}

	&_wrapimg {
		position: relative;

		.a-image {
			max-width: rem(540);
		}

		.a-icon {
			position: absolute;
			top: -5px;
			right: -5px;
			width: rem(14);
			height: rem(14);
		}
	}
}
