html {
	font-size: $FONT-SIZE-HTML;
	line-height: 24px;
	word-break: break-word;
}

body {
	margin: 0;
	font-family: $FONTFAMILY-BASIC;
	color: $COLOR-WHITE;
	@include u-fw-bold;
}

// Default a-text style for p tags
p {
	font-size: rem($FONT-SIZE-HTML-VALUE);

	&:last-child {
		margin-bottom: 0;
	}
}

// Default a-textlink style for a tags
a {
	color: $COLOR-SPANISH-BLUE;
	text-decoration: none;

	&:hover {
		color: inherit;
	}
}

img {
	max-width: 100%;
}

button,
select {
	font-family: inherit;

	&:focus {
		border: none;
		outline: none;
		appearance: none;
	}
}

[role="button"],
button {
	cursor: pointer;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

b,
strong {
	@include u-fw-bold;
}

$offsets: (top, bottom, left, right);
$breakpoints: (
	sm: "(min-width: 576px)",
	md: "(min-width: 768px)",
	lg: "(min-width: 992px)",
	xl: "(min-width: 1200px)",
);

@each $offset in $offsets {
	@for $spacing from -100 through 100 {
		@if $spacing >= 0 {
			.u-m#{str-slice($offset, 0, 1)}-#{$spacing} {
				margin-#{$offset}: #{$spacing}px;
			}
			.u-p#{str-slice($offset, 0, 1)}-#{$spacing} {
				padding-#{$offset}: #{$spacing}px;
			}
		} @else {
			.u-m#{str-slice($offset, 0, 1)}-negative#{$spacing} {
				margin-#{$offset}: #{$spacing}px;
			}
		}
	}
}

@each $name, $value in $breakpoints {
	@each $offset in $offsets {
		@for $spacing from -100 through 100 {
			@if $spacing >= 0 {
				.u-m#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
					@media #{$value} {
						margin-#{$offset}: #{$spacing}px;
					}
				}

				.u-p#{str-slice($offset, 0, 1)}-#{$name}-#{$spacing} {
					@media #{$value} {
						padding-#{$offset}: #{$spacing}px;
					}
				}
			} @else {
				.u-m#{str-slice($offset, 0, 1)}-negative-#{$name}#{$spacing} {
					@media #{$value} {
						margin-#{$offset}: #{$spacing}px;
					}
				}
			}
		}
	}
}

@each $name, $value in $breakpoints {
	@for $spacing from -500 through 500 {
		.minw-#{$name}-#{$spacing} {
			@media #{$value} {
				min-width: #{$spacing}px;
			}
		}
		.maxw-#{$name}-#{$spacing} {
			@media #{$value} {
				max-width: #{$spacing}px;
			}
		}
	}
}

// Set the background-color of the body Iframe to transparent
.sb-show-main {
	background-color: $COLOR-TRANSPARENT;
}
