.a-text {
	$root: &;
	margin-bottom: 0;

	&-centered {
		text-align: center;
	}

	&-black {
		color: $COLOR-BLACK;
	}

	&-spanishBlue {
		color: $COLOR-SPANISH-BLUE;
	}

	&-charlestonGreen {
		color: $COLOR-CHARLESTON-GREEN;
	}

	&-yankeesBlue {
		color: $COLOR-YANKEES-BLUE;
	}

	&-18x26 {
		font-size: 18px;
		line-height: 26px;
	}
}
