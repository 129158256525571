.t-mainlayout {
	background: url("~assets/images/common/background.png") center/cover no-repeat;
	background-attachment: fixed;

	> .o-main {
		min-height: calc(100vh - 73.03px);
	}

	// &-glucerna {
	// 	background: url("~assets/images/common/background-glu.png") center/cover
	// 		no-repeat;
	// }

	&_bottom {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 15px;
		margin: 15px 15px 0;

		> .a-image {
			width: 105px;
		}

		> .a-text {
			font-size: 14px;
			color: rgb(150, 150, 151);
		}
	}

	&_notes {
		position: absolute;
		top: 15px;
		right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 25px;
		height: 25px;
		padding: 4px;
		background-color: rgba(255, 255, 255, 0.87);
		border-radius: 50%;

		img {
			width: 100%;
		}
	}
}
