/*
* https://hexcol.com/
*	description: Use this tool to get the Color-Name
*/

/* #region COLOR-BASE */
$COLOR-TRANSPARENT: transparent;
$COLOR-BLACK: rgb(0, 0, 0);
$COLOR-WHITE: rgb(255, 255, 255);
$COLOR-SPANISH-BLUE: rgb(0, 110, 183);
$COLOR-CINNABAR: rgb(241, 67, 54);
$COLOR-GAINSBORO: rgb(216, 218, 234);
$COLOR-DARK-MIDNIGHT-BLUE: rgb(9, 63, 102);
$COLOR-CHARLESTON-GREEN: rgb(42, 42, 43);
$COLOR-CINNABAR: rgb(241, 67, 54);
$COLOR-SUNSET-ORANGE: rgb(240, 84, 84);
$COLOR-LANGUID-LAVENDER: rgb(205, 207, 221);
$COLOR-YANKEES-BLUE: rgb(21, 40, 63);
$COLOR-PRUSSIAN-BLUE: rgb(4, 55, 91);
$COLOR-PRUSSIAN-BLUE2: rgb(5, 52, 86);
$COLOR-MEDIUM-PERSIAN-BLUE: rgb(8, 106, 168);
$COLOR-AQUA: rgb(39, 249, 252);
/* #endregion */

/* #region FONT-FAMILY */
$FONTFAMILY-BASIC: "icielBrandonText";
/* #endregion */

/* #region FONT-SIZE html */
$FONT-SIZE-HTML-VALUE: 16;
$FONT-SIZE-HTML: 16px;
/* #endregion */

/* #region SIZE-SCREEN */
$WIDTH-PC-LARGE: 1920px;
$WIDTH-PC-MEDIUM: 1366px;
$WIDTH-PC: 1280px;
$WIDTH-TAB: 1024px;
$WIDTH-SM: 768px;
$WIDTH-XS: 576px;
/* #endregion */
