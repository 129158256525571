.a-loading {
	$root: &;
	$animation: dot-flashing 1s infinite alternate;

	@include align-middle;
	.a-icon {
		width: 45px;
		height: 45px;
	}
}
