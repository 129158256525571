.a-spinner {
	$root: &;
	position: relative;

	&_child {
		pointer-events: none;
		opacity: 0.5;
	}

	&-wrap {
		min-height: rem(48);
		& > .a-icon {
			@include align-middle;
		}
	}

	& > .a-icon {
		width: rem(72);
		height: rem(72);
	}
}
