.a-radio {
	$root: &;
	display: block;

	&_input {
		position: absolute;
		pointer-events: none;
		opacity: 0;
	}

	&_label {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		cursor: pointer;
	}

	&_body {
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: center;
		width: rem(20);
		height: rem(20);
		cursor: pointer;
		background: radial-gradient(rgb(255, 255, 255) 0%, rgb(245, 245, 245) 100%);
		border: 1px solid rgb(59, 165, 88);
		border-radius: 50%;
		transition: 0.3s ease-in-out;

		#{$root}_input:hover + & {
			opacity: 0.8;
		}
	}

	&_inner {
		width: rem(12);
		height: rem(12);
		border-radius: 50%;
		transition: all 0.2s ease-in-out;

		#{$root}_input:checked + #{$root}_body > & {
			background-color: rgb(59, 165, 88);
		}
	}

	&_content {
		margin-left: rem(8);
		line-height: rem(24);
		color: rgb(255, 255, 255);
	}
}
