.a-image {
	$root: &;
	position: relative;
	overflow: hidden;

	&_img {
		max-width: 100%;
		transition: all 0.3s ease-in-out;

		#{$root}-fixed & {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			display: block;
			height: 100%;
			margin: auto;
		}

		#{$root}-fixed & {
			object-fit: cover;
			width: 100%;
		}
	}

	&-fixed {
		background-color: rgba($COLOR-BLACK, 0.5);
	}

	&-transparent {
		background-color: $COLOR-TRANSPARENT;
	}

	&-rounded {
		border-radius: 20px;
	}

	&-boxShadow {
		box-shadow: 2px 2px 6px 0px rgb(44, 44, 44);
	}

	&-photo {
		@include aspect-ratio(662, 713);
	}

	&-loginbanner {
		@include aspect-ratio(900, 602);
	}

	&-capture {
		@include aspect-ratio(330, 231);
	}
}
