.t-photo {
	padding-top: 20px;
	@include pcFixedContent {
		display: none;
	}
	&_thumbnail {
		display: none;
		@include pcFixedContent {
			display: block;
		}
	}

	&_capture {
		position: relative;
		width: 100%;
		padding-bottom: 70%;
		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&_image {
		padding: 0 14px;
	}
}
