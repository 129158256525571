.p-draw {
	overflow-x: hidden;

	&_logo {
		width: 182px;
		padding-top: 16px;
		margin-right: 8px;
		margin-bottom: -20px;
		margin-left: 8px;

		@include sp {
			width: 110px;
			margin-bottom: 0;
		}
	}

	&_wheelwrapper {
		position: relative;
		margin-bottom: 15px;
	}

	&_arrowtop {
		position: absolute;
		top: -20px;
		left: calc(50% + 2px);
		z-index: 3;
		width: 90px;
		transform: translateX(-50%);

		@include sp {
			width: 65px;
		}
	}

	&_arrowbottom {
		position: absolute;
		top: -23px;
		left: 50%;
		z-index: 1;
		width: 120px;
		transform: translateX(-50%);

		@include sp {
			width: 87px;
		}
	}

	&_wheel {
		position: relative;
		left: 50%;
		z-index: 2;
		width: 100vw;
		max-width: 427px;
		height: 100vw;
		max-height: 427px;
		transform: translateX(-50%);

		@include sp {
			max-width: 320px;
			max-height: 320px;
		}

		.wheel {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			background-image: url("../../assets/images/common/wheel.png");
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&_drawtrigger {
		position: relative;
		z-index: 3;
	}
}
