.a-textfield {
	$root: &;
	position: relative;

	&_input {
		width: 100%;
		height: 43px;
		padding: rem(10) rem(16);
		color: rgb(109, 110, 112);
		background: linear-gradient(
			90deg,
			rgb(255, 255, 255) 0%,
			rgb(245, 245, 245) 100%
		);
		border: 2px solid rgb(18, 124, 47);
		border-radius: rem(8);
		outline: none;
		transition: 0.3s ease-in-out;
		appearance: none;

		&[type="number"] {
			padding: 10px;
		}

		&::placeholder {
			color: rgb(161, 162, 165);
		}

		&:hover,
		&:focus,
		&:focus-within {
			border-color: rgb(157, 220, 174);
		}

		#{$root}-error & {
			color: $COLOR-CINNABAR;
			border: 1px solid $COLOR-CINNABAR;
		}
	}

	&_errormessage {
		@include error-text;
	}
}
